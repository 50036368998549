import styled from 'styled-components';
import {
  DefaultTheme,
  PopoverContent,
  ThemedComponent,
} from '@la/ds-ui-components';

type ExternalLocationOptionProps = {
  $noHover?: boolean;
  theme: DefaultTheme;
};

export const ExternalLocationOptionContainer =
  styled.div<ExternalLocationOptionProps>(
    ({ $noHover, theme }: ExternalLocationOptionProps) => {
      const { foundational } = theme;
      const backgroundHover = $noHover ? 'inherit' : foundational.blueGrey[50];

      return {
        cursor: 'pointer',
        display: 'grid',
        width: '100%',
        background: 'transparent',
        padding: '9px 16px',
        textAlign: 'left',
        '&[aria-selected="true"],:hover': {
          background: foundational.blueGrey[50],
        },
        ':hover': {
          background: backgroundHover,
        },
      };
    }
  );

export const ExternalLocationOptionLink = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { green } = theme.foundational;

    return {
      ...theme.typography.ui.medium,
      fontWeight: 500,
      textDecoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: green[600],
    };
  }
);

export const AddNewLocationInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  > div:nth-child(1) {
    flex-grow: 1;
  }
`;

export const ExternalLocationOption = styled.div<{ $isDisabled?: boolean }>`
  padding: 0;
  > p {
    color: ${({ $isDisabled }) =>
      $isDisabled ? 'var(--blue-grey-250)' : 'var(--black)'};
  }
`;

export const DescriptionTextArea = styled.textarea<ThemedComponent>(
  ({ theme }) => {
    return {
      ...theme.typography.ui.large,
      boxSizing: 'border-box',
      color: 'rgb(38, 50, 56)',
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid',
      borderRadius: '4px',
      borderColor: 'rgb(120, 144, 156)',
      padding: '4px 8px',
      cursor: 'pointer',
      maxWidth: '100%',
      maxHeight: '100%',
      resize: 'both',
      pointerEvents: 'auto',
      margin: '4px 0',
      width: '100%',

      '&:focus-within': {
        outline: 'rgb(120, 144, 156) solid 2px',
        outlineOffset: '2px',
      },
      '&::placeholder': {
        ...theme.typography.ui.large,
        color: 'rgb(120, 144, 156)',
        fontStyle: 'italic',
      },
    };
  }
);

export const Label = styled.label<{ error?: boolean; disabled?: boolean }>`
  color: ${({ error, disabled }) => {
    if (error) {
      return 'var(--red-600)';
    } else if (disabled) {
      return 'var(--blue-grey-200)';
    }
    return 'var(--blue-grey-600)';
  }};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ExternalLocationAddressPopover = styled(PopoverContent)<{
  $modalWidth: number;
}>`
  width: ${(props) => props.$modalWidth}px;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border: 1px solid black;
  z-index: 1200;
  margin-top: 204px;
`;

export const ExternalLocationAddressOption = styled.div`
  display: flex;

  button {
    text-align: left;
    height: 100%;
  }
`;
