import { forwardRef, useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  CaretLeftIcon,
  PlusCircleIcon,
  Popover,
  PopoverTrigger,
  SelectOption,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  GooglePlacesAutoCompleteSuggestions,
  useGetPlacesAutoCompleteQuery,
  useLazyGetPlacesInfoQuery,
} from 'redux/services/googleMaps';
import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { useAppSelector } from 'redux/store';
import * as S from './ExternalLocationSelect.styles';

type ExternalLocationSelectProps = {
  onChange: (value?: string) => void;
  onClose: (value?: string) => void;
  locations: SelectOption[];
  value?: string;
};

type CreateExternalLocationProps = {
  onBack: () => void;
  showCreateExternalLocation: boolean;
};

type AutoCompleteAddressProps = {
  placeId: string;
  placeName: string;
  onSelectAddress: (placeId: string) => void;
  buttonWidth: number;
};

type AutoCompleteAddressDropdownProps = {
  formattedPredictions: PlacePrediction[];
  onSelectAddress: (placeId: string) => void;
  buttonWidth: number;
};

type PlacePrediction = {
  placeId: string;
  placeName: string;
};

export const ExternalLocationSelect = forwardRef<
  HTMLDivElement,
  ExternalLocationSelectProps
>(({ onChange, onClose, locations, value }, ref?) => {
  const { siteId } = useAppSelector(getSiteIdentityData);
  const { data: siteSettings } = useGetSiteSettingsQuery(Number(siteId), {
    skip: !siteId,
  });
  const { teamStaffCustomLocationsCreation } = useFlags();
  const allowCreateNewLocation =
    teamStaffCustomLocationsCreation && siteSettings?.appCreateLocation;

  const [showCreateExternalLocation, setShowCreateExternalLocation] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOnSelect = (location?: string) => {
    onChange(location);
    onClose(location);
  };

  const handleOnBack = (locationId?: string) => {
    setShowCreateExternalLocation(false);

    if (locationId) {
      setSelectedValue(locationId);
      onChange(locationId);
      onClose(locationId);
    }
  };

  return (
    <div>
      {!showCreateExternalLocation ? (
        <>
          {!!allowCreateNewLocation && (
            <S.ExternalLocationOptionContainer
              role="option"
              aria-selected="false"
              onClick={() => setShowCreateExternalLocation(true)}
            >
              <S.ExternalLocationOption>
                <S.ExternalLocationOptionLink>
                  <PlusCircleIcon fill="#007A00" variant="regular" /> Add a new
                  location
                </S.ExternalLocationOptionLink>
              </S.ExternalLocationOption>
            </S.ExternalLocationOptionContainer>
          )}

          {locations.map((location) => {
            return (
              <S.ExternalLocationOptionContainer
                role="option"
                aria-disabled={false}
                aria-selected={selectedValue === location.value ? true : false}
                onClick={() => handleOnSelect(location.value?.toString())}
                key={location.value}
              >
                <S.ExternalLocationOption $isDisabled={false}>
                  <Typography variant="ui" size="large" weight="regular">
                    {location.label}
                  </Typography>
                </S.ExternalLocationOption>
              </S.ExternalLocationOptionContainer>
            );
          })}
        </>
      ) : (
        <CreateExternalLocation
          onBack={handleOnBack}
          showCreateExternalLocation={showCreateExternalLocation}
        />
      )}
    </div>
  );
});

export const CreateExternalLocation = forwardRef<
  HTMLDivElement,
  CreateExternalLocationProps
>(({ onBack, showCreateExternalLocation }, ref?) => {
  const [inputAddress, setInputAddress] = useState('');
  const [skipToken, setSkipToken] = useState(false);
  const [placeId, setPlaceId] = useState('');
  const [externalLocationName, setExternalLocationName] = useState('');

  // We should eventually remove the requirement of putting this in the path for the request since we rly dont need it.
  const { subdomain: siteDomain } = getLAHostnameParts();

  const { data: autoCompleteData } = useGetPlacesAutoCompleteQuery(
    { input: inputAddress, siteDomain },
    {
      skip: !inputAddress.length || skipToken,
      refetchOnMountOrArgChange: true,
    }
  );
  const [getLazyPlaceInfo] = useLazyGetPlacesInfoQuery();

  const formatPredictions = (
    predictions: GooglePlacesAutoCompleteSuggestions[]
  ): PlacePrediction[] => {
    return predictions.map((i: GooglePlacesAutoCompleteSuggestions) => ({
      placeId: i?.placePrediction?.placeId,
      placeName: i?.placePrediction?.text?.text,
    }));
  };
  const predictions = autoCompleteData?.suggestions ?? [];
  const formattedPredictions = predictions.length
    ? formatPredictions(predictions)
    : [];

  const externalLocationRef = useRef<HTMLInputElement>(null);

  const handleSelectAddress = async (placeId: string) => {
    // Set place
    setPlaceId(placeId);
    const data = await getLazyPlaceInfo({
      input: placeId,
      siteDomain: siteDomain,
    }).unwrap();
    // Set address associated to the place + the name if we have it
    setInputAddress(data?.formattedAddress);
    if (data.displayName?.text && externalLocationName === '') {
      setExternalLocationName(data?.displayName?.text);
    }
  };

  // used to debounce the search so we don't send so many reqs
  useEffect(() => {
    setSkipToken(true);
    const timer = setTimeout(() => {
      setSkipToken(false);
    }, 750);

    return () => {
      clearTimeout(timer);
    };
  }, [inputAddress]);

  const showExternalAddressPopOver =
    showCreateExternalLocation &&
    !!inputAddress.length &&
    !!formattedPredictions.length &&
    !placeId;

  const popOverWidth = externalLocationRef.current?.clientWidth ?? 700;

  return (
    <S.ExternalLocationOptionContainer
      role="option"
      aria-selected="false"
      $noHover={true}
    >
      <S.ExternalLocationOption>
        <S.ExternalLocationOptionLink onClick={() => onBack()}>
          <CaretLeftIcon fill="#007A00" variant="regular" /> Back to location
          selection
        </S.ExternalLocationOptionLink>
        <S.AddNewLocationInput>
          <Popover open={showExternalAddressPopOver}>
            <PopoverTrigger>
              <span>
                <TextInput
                  id="external-location-address"
                  label="Location address"
                  placeholder="Add location address"
                  required
                  hasError={false}
                  errorMessage=""
                  value={inputAddress}
                  onChange={(e) => {
                    setInputAddress(e.target.value);
                    setPlaceId('');
                  }}
                  ref={externalLocationRef}
                />
              </span>
            </PopoverTrigger>
            <S.ExternalLocationAddressPopover
              sideOffset={-200}
              arrow={false}
              avoidCollisions={false}
              side="bottom"
              $modalWidth={popOverWidth + 17}
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <AutoCompleteAddressDropdown
                formattedPredictions={formattedPredictions}
                onSelectAddress={handleSelectAddress}
                buttonWidth={popOverWidth}
              />
            </S.ExternalLocationAddressPopover>
          </Popover>
          <TextInput
            id="external-location-name"
            label="Location name"
            placeholder="Add location name"
            required
            hasError={false}
            value={externalLocationName}
            onChange={(e) => setExternalLocationName(e.target.value)}
            errorMessage=""
          />

          <div>
            <Typography variant="ui" size="medium" weight="bold">
              <S.Label>Location Description</S.Label>
            </Typography>
            <S.DescriptionTextArea
              id="notes"
              placeholder="Add location description"
              rows={3}
            />
          </div>
          <S.ButtonContainer>
            <Button size="large" onClick={() => {}}>
              Add Location
            </Button>
          </S.ButtonContainer>
        </S.AddNewLocationInput>
      </S.ExternalLocationOption>
    </S.ExternalLocationOptionContainer>
  );
});

const AutoCompleteAddressDropdown = ({
  formattedPredictions,
  onSelectAddress,
  buttonWidth,
}: AutoCompleteAddressDropdownProps) => {
  return (
    <div>
      {formattedPredictions.map((placePrediction: PlacePrediction) => {
        return (
          <S.ExternalLocationAddressOption key={placePrediction.placeId}>
            <AutoCompleteAddress
              placeId={placePrediction.placeId}
              placeName={placePrediction.placeName}
              onSelectAddress={onSelectAddress}
              buttonWidth={buttonWidth}
            />
          </S.ExternalLocationAddressOption>
        );
      })}
    </div>
  );
};

const AutoCompleteAddress = ({
  placeId,
  placeName,
  onSelectAddress,
}: AutoCompleteAddressProps) => {
  return (
    <Button
      onClick={() => {
        onSelectAddress(placeId);
      }}
      variant="text"
    >
      <Typography variant="ui" size="large">
        {placeName}
      </Typography>
    </Button>
  );
};
