import baseApi from './baseApi';

export type GetPlacesAutoCompleteInput = {
  input: string;
  siteDomain: string;
};

export type GooglePlacesAutoCompleteResponse = {
  suggestions: GooglePlacesAutoCompleteSuggestions[];
};

export type GooglePlacesAutoCompleteSuggestions = {
  placePrediction: {
    placeId: string;
    text: {
      text: string;
    };
  };
};

export type GooglePlacesInfoResponse = {
  formattedAddress: string;
  displayName: {
    text: string;
  };
};

// Define goole maps api service
export const googleMapsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlacesAutoComplete: builder.query<
      GooglePlacesAutoCompleteResponse,
      GetPlacesAutoCompleteInput
    >({
      query: ({ input, siteDomain }) => {
        const urlSafeInput = encodeURI(input);
        return {
          url: `/api/member-portal/${siteDomain}/googlePlaces/googleMapsPlacesAutocomplete?input=${urlSafeInput}`,
          method: 'GET',
        };
      },
    }),
    getPlacesInfo: builder.query<
      GooglePlacesInfoResponse,
      GetPlacesAutoCompleteInput
    >({
      query: ({ input, siteDomain }) => {
        const urlSafePlaceId = encodeURI(input);
        return {
          url: `/api/member-portal/${siteDomain}/googlePlaces/googleMapsPlacesInfo?placeId=${urlSafePlaceId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetPlacesAutoCompleteQuery,
  useGetPlacesInfoQuery,
  useLazyGetPlacesInfoQuery,
} = googleMapsApi;
